import { Grid } from '@mui/material';
import { FC } from 'react';
import ProportalBulletTile from './ProportalBulletTile';

interface IProportalTiles {
  hideTileDescriptionMarginTop?: boolean;
}

const features = [
  {
    description:
      'Our specialized service manages tax filings and tax agency correspondence — with guaranteed accuracy.',
    title: 'Complete Compliance Coverage',
    key: 1,
  },
  {
    description:
      'Designed for household employers, our system takes the work out of payroll management.',
    title: 'Streamlined Payroll Management',
    key: 2,
  },
  {
    description:
      'ProPortal gives you on-demand anytime access to organized tax forms and client records.',
    title: 'Real-Time Client Access',
    key: 3,
  },
  {
    description:
      'Get clients up and running in under five minutes with our simple, paperless system.',
    title: 'Seamless Payroll Onboarding',
    key: 4,
  },
];

const ProportalTiles: FC<IProportalTiles> = ({ hideTileDescriptionMarginTop }) => {
  return (
    <Grid container direction="column" data-testid="tiles-container">
      {features.map((feature) => (
        <Grid item key={feature.key}>
          <ProportalBulletTile
            title={feature.title}
            description={feature.description}
            hideTileDescriptionMarginTop={hideTileDescriptionMarginTop}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProportalTiles;
