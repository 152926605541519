import { theme } from '@care/material-ui-theme';
import { Box, Grid, useMediaQuery, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IProportalBulletTile {
  description: string | ReactNode;
  title: string | ReactNode;
  hideTileDescriptionMarginTop?: boolean;
}

const ProportalBulletTile: FC<IProportalBulletTile> = ({
  description,
  title,
  hideTileDescriptionMarginTop,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      alignItems="flex-start"
      direction="row"
      marginLeft={theme.spacing(isMobile ? 2 : 0)}
      sx={{
        gap: {
          xs: theme.spacing(2),
          md: theme.spacing(3),
        },
      }}
      justifyContent="center">
      <Box
        sx={{
          backgroundColor: theme.palette.care.navy[800],
          borderRadius: '50%',
          height: '16px',
          marginTop: theme.spacing(0.75),
          width: '16px',
        }}
      />
      <Grid item justifyContent="space-between" xs={11} md={9} paddingRight={2}>
        <Typography
          sx={{
            textAlign: 'left',
          }}
          variant="h2">
          {title}
        </Typography>
        <Typography
          sx={{
            textAlign: 'left',
            margin: hideTileDescriptionMarginTop
              ? theme.spacing(0, 0, 1.5)
              : theme.spacing(3, 0, 3),
          }}
          variant="body2"
          color="secondary">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProportalBulletTile;
